<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card v-if="pageDetails">
      <div class="mb-3 w-100 d-flex align-items-center justify-content-between">
        <h1>ویرایش برگه</h1>
        <b-form-group
          label="صفحه غیر قابل حذف باشد؟"
          v-slot="{ ariaDescribedby }"
        >
          <div
            class="w-100 d-flex align-items-center justify-content-center"
            style="gap: 1rem"
          >
            <b-form-radio
              v-model="pageDetails.isDeletable"
              :aria-describedby="ariaDescribedby"
              name="isDeletable"
              :value="false"
            >
              بله
            </b-form-radio>
            <b-form-radio
              v-model="pageDetails.isDeletable"
              :aria-describedby="ariaDescribedby"
              name="isDeletable"
              :value="true"
            >
              خیر
            </b-form-radio>
          </div>
        </b-form-group>
      </div>
      <b-row>
        <b-col cols="6">
          <b-form @submit.prevent>
            <b-form-group label="عنوان برگه" label-for="title">
              <b-form-input
                id="title"
                trim
                placeholder="درباره ما یا ..."
                v-model="pageDetails.title"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="6">
          <b-form @submit.prevent>
            <b-form-group label="لینک برگه (با - جدا شود)" label-for="link">
              <b-form-input
                dir="auto"
                id="link"
                trim
                placeholder="about-us"
                v-model="pageDetails.link"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="6">
          <language-picker
            :defaultLanguageId="pageDetails.languageId"
            @getLanguage="setLanguage"
            placeholder="زبان برگه را انتخاب کنید"
          ></language-picker>
        </b-col>
        <b-col cols="6">
          <b-form-group label-for="pageShapeType" label="نوع ظاهری برگه">
            <b-form-select
              id="pageShapeType"
              v-model="pageDetails.pageShapeType"
              :options="shapeOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <menu-picker
            placeholder="انتخاب فهرست هدر صفحه"
            :defaultMenuId="pageDetails.menuId"
            @getMenu="setMenu"
          ></menu-picker>
        </b-col>
        <b-col cols="12">
          <b-form-group label="محتوای برگه" label-for="page-content">
            <quillEditor
              ref="quillEditor"
              v-model="pageDetails.content"
              :options="editorOption"
            >
              <div id="toolbar" slot="toolbar" class="ql-toolbar ql-snow">
                <quill-toolbar></quill-toolbar>
              </div>
            </quillEditor>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="my-1">
          <b-form @submit.prevent>
            <b-form-group label="کاور برگه" label-for="imageFile">
              <b-form-file
                id="imageFile"
                v-model="pageImage"
                accept="image/*"
                @input="convertImageFileToBase64"
              ></b-form-file>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="6" class="my-1">
          <div v-if="imagePreview">
            <p>پیشنمایش تصویر برگه</p>
            <div class="position-relative">
              <img
                style="width: 100%; max-height: 20rem; object-fit: contain"
                :src="imagePreview"
                alt=""
              />
              <feather-icon
                icon="Trash2Icon"
                size="25"
                class="text-danger position-absolute"
                style="left: 1rem; top: 1rem; cursor: pointer"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button variant="primary" @click="sendPageUpdateRequest">
          ویرایش برگه
        </b-button>
      </div>
    </b-card>
    <library-image-picker @insertImage="insertImage"></library-image-picker>
  </b-overlay>
</template>
<script>
export default {
  title: "ویرایش برگه",
  data() {
    return {
      isLoading: false,
      pageImage: null,
      pageDetails: null,
      shapeOptions: [
        {
          value: null,
          text: "یک گزینه را انتخاب کنید",
        },
        {
          value: 1,
          text: "صفحه اصلی",
        },
        {
          value: 2,
          text: "بلاگ",
        },
        {
          value: 3,
          text: "تماس با ما",
        },
        {
          value: 4,
          text: "نمایندگی",
        },
        {
          value: 5,
          text: "خدمت",
        },
        {
          value: 6,
          text: "داینامیک",
        },
      ],
      editorOption: {
        modules: {
          toolbar: {
            container: "#toolbar",
            handlers: {
              image: this.imageMethod,
            },
          },
        },
        // Some Quill options...
        theme: "snow",
      },
    };
  },
  mounted() {
    this.getASinglePage();
  },
  methods: {
    imageMethod() {
      this.$bvModal.show("library-image-picker-modal");
    },
    insertImage(imageUrl) {
      this.$refs.quillEditor.quill.insertEmbed(
        this.$refs.quillEditor.quill.getSelection().index,
        "image",
        imageUrl
      );
    },
    setMenu(menu) {
      this.pageDetails.menuId = menu.menuId;
    },
    setLanguage(language) {
      this.pageDetails.languageId = language.languageId;
    },
    async getASinglePage() {
      try {
        this.isLoading = true;
        let _this = this;
        let getASinglePage = new GetASinglePage(_this);
        getASinglePage.setRequestParam({
          id: _this.$route.params.id,
        });
        await getASinglePage.fetch((response) => {
          if (response.isSuccess) {
            _this.pageDetails = response.data;
            // if (!_this.pageDetails.isStatic) {
            //   if (_this.pageDetails.link.includes("/pages/")) {
            //     _this.pageDetails.link =
            //       _this.pageDetails.link.split("/pages/")[1];
            //   }
            // }
            _this.pageImage = JSON.parse(
              JSON.stringify(_this.pageDetails.fileData)
            );
            _this.pageDetails.fileData = [];
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async sendPageUpdateRequest() {
      try {
        this.isLoading = true;
        if (!this.pageDetails.isStatic) {
          if (!this.pageDetails.link.startsWith("/")) {
            this.pageDetails.link = `/${this.pageDetails.link}`;
          }
          this.pageDetails.seourl = this.pageDetails.link;
        }
        let _this = this;
        let updateASinglePage = new UpdateASinglePage(_this);
        updateASinglePage.setRequestParamDataObj(_this.pageDetails);
        await updateASinglePage.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `برگه با موفقیت ویرایش شد.`,
              },
            });
            _this.getASinglePage();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    convertImageFileToBase64() {
      const reader = new FileReader();
      reader.readAsDataURL(this.pageImage);
      reader.onload = () => {
        this.pageDetails.fileData.push({
          base64: reader.result.split(",")[1],
          priority: 1,
          link: null,
          imageTitle: null,
        });
      };
    },
  },
  computed: {
    imagePreview() {
      if (this.pageImage[0] && typeof this.pageImage[0].base64 != "string") {
        return URL.createObjectURL(this.pageImage);
      } else {
        if (this.pageImage && this.pageImage[0] && this.pageImage[0].base64) {
          return `${process.env.pic}/${this.pageImage[0].base64}`;
        } else {
          return false;
        }
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormRadio,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    LanguagePicker,
    quillEditor,
    MenuPicker,
    LibraryImagePicker,
    QuillToolbar,
  },
};
import MenuPicker from "@/views/components/utils/MenuPicker.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { GetASinglePage, UpdateASinglePage } from "@/libs/Api/Page";
import QuillToolbar from "@/views/components/utils/QuillToolbar.vue";
import LibraryImagePicker from "@/views/components/utils/LibraryImagePicker.vue";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BFormRadio,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
</script>
