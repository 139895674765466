<template>
  <b-modal
    id="library-image-picker-modal"
    size="lg"
    title="انتخاب عکس از کتابخانه"
    ok-title="انتخاب"
    cancel-title="بستن"
    centered
    @ok="insertImage"
  >
    <b-container fluid v-if="libraryList">
      <b-form-row>
        <b-col
          cols="2"
          v-for="image in libraryList"
          :key="image.mediaId"
          class="position-relative h-auto cursor-pointer"
          @click="selectImage(image)"
        >
          <div
            class="overlay"
            v-if="selectedImage && selectedImage.mediaId == image.mediaId"
          ></div>
          <img :src="handleImageUrl(image)" alt="" class="w-100 h-auto" />
          <feather-icon
            v-if="selectedImage && selectedImage.mediaId == image.mediaId"
            icon="CheckIcon"
            size="30"
            class="custom-selected-icon text-success"
          />
        </b-col>
      </b-form-row>
    </b-container>
  </b-modal>
</template>
<script>
export default {
  emits: ["insertImage"],
  mounted() {
    this.getAllLibraryFiles();
  },
  data() {
    return {
      isLoading: false,
      libraryList: null,
      count: 0,
      pageNumber: 0,
      totalCount: null,
      selectedImage: null,
    };
  },
  methods: {
    insertImage() {
      this.$emit("insertImage", this.handleImageUrl(this.selectedImage));
      this.selectedImage = null;
    },
    selectImage(image) {
      this.selectedImage = image;
    },

    handleImageUrl(image) {
      return `${Helper.baseUrl}/Media/Gallery/Library/${image.pictureUrl}`;
    },
    async getAllLibraryFiles() {
      try {
        this.isLoading = true;
        let _this = this;
        let getLibraryFiles = new GetLibraryFiles(_this);
        getLibraryFiles.setRequestParam({
          count: _this.count,
          pageNumber: _this.pageNumber,
        });
        await getLibraryFiles.fetch((response) => {
          if (response.isSuccess) {
            _this.libraryList = response.data.medias;
            _this.totalCount = response.data.mediasCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BModal,
    BContainer,
    BFormRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },
};
import Helper from "@/libs/Helper";
import { GetLibraryFiles } from "@/libs/Api/Media";
import {
  BModal,
  BContainer,
  BFormRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
</script>
<style scoped>
.custom-selected-icon {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.overlay {
  background-color: rgba(189, 189, 189, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
