import WebServiceRequest from "../Api/WebServiceRequest";

class CreateNewMenu extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Menu/Create");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetAllMenu extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Menu/GetAll");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

export { CreateNewMenu, GetAllMenu };
